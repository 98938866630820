<script setup lang="ts">
import SVG_BANNER_RIGHT_TRIANGLE from '/assets/svg/banner-right-triangle.svg'
import { EGameType, type ICommonGameBlockProps } from '@/types/components'

interface Props {
  game: ICommonGameBlockProps
  lazy?: boolean
  iconClass?: any
}

defineOptions({ inheritAttrs: false })

const props = defineProps<Props>()

const gameImgUrl = computed(() => scaleImage(props.game.icon, 180))

const isAutoStart = ref(false)
const gameDetailUrl = computed(() => `/${props.game.gameType === EGameType.CLOUD ? 'games' : 'webgames'}/${props.game.seo}.html${isAutoStart.value ? '?start=1' : ''}`)
</script>

<template>
  <NuxtLink class="group" :to="gameDetailUrl">
    <div class="flex items-center gap-3 rd-2 p-2 text-3.5 transition-300 group-hover:bg-gray-4" v-bind="$attrs">
      <img v-if="lazy" v-lazy="gameImgUrl" class="aspect-ratio-1 flex-shrink-0 rd-3 md:rd-1.5" :class="iconClass" :alt="game.gameName">
      <img v-else :class="iconClass" class="aspect-ratio-1 flex-shrink-0 rd-3 md:rd-1.5" :src="gameImgUrl" :alt="game.gameName">

      <span class="flex-1 truncate c-#fff font-600 lh-1.25em">{{ game.gameName }}</span>

      <button
        class="ld-button pointer-events-none h-9 flex-shrink-0 px-3 op-0 transition-300 lt-md:pointer-events-auto lt-md:op-100 ld-button-green"
        group-hover="op-100 pointer-events-auto" @mouseenter="isAutoStart = true" @mouseleave="isAutoStart = false"
      >
        <SVG_BANNER_RIGHT_TRIANGLE class="size-1.25em" />
        <span>{{ $t('common.play') }}</span>
      </button>
    </div>
  </NuxtLink>
</template>

<style lang="scss" scoped>
</style>
